// Component's Classes
import { InputBase } from '@/Components/Global/DataForm/Classes/InputBase'

// Class Export
export class InputNumber extends InputBase {
	key: string
	label: string

	constructor(key: string, label: string) {
		super()
		this.key = key
		this.label = label
		this._setType('number')
		this.setState(this._defaultInputNumberValidator)
	}

	private _defaultInputNumberValidator(self: InputNumber) {
		if (self.value === '') return null
		const value = parseInt(self.value)
		const range = self._getRange()
		
		if (range) {
			const { min, max } = range
			if (min && max) {
				return value > (min - 1) && value < (max + 1)
			}
			else {
				return value > (min - 1)
			}
		}

		return true
	}

	private _getRange(): { min?: number, max?: number } {
		return this.params.range
	}

	public setRange(min?: number, max?: number) {
		this.params.range = { min, max }
		if (!max) delete this.params.range.max
		return this
	}
}